import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
  Table,
} from 'reactstrap';
import designStudioService from '../../../../services/api/design-studio';
import { Lead } from '../../../../services/api/design-studio/types';

interface BelmondLookupModalProps {
  isOpen: boolean;
  toggle: () => void;
  onLeadSelect: (lead: Lead) => void;
  showPhoneNumber: boolean;
}

interface SearchFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const BelmondLookupModal = ({
  isOpen,
  toggle,
  onLeadSelect,
  showPhoneNumber,
}: BelmondLookupModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm<SearchFormData>();

  const onSubmit = async (values: SearchFormData) => {
    setLoading(true);
    setLeads([]);
    try {
      const { data } = await designStudioService.getLeadsList({
        department: null,
        isActive: true,
        email: values.email || null,
        search: `${values.firstName || ''} ${values.lastName || ''}`,
      });
      setLeads(data.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onCloseModal = () => {
    setSelectedLead(null);
    setLeads([]);
    reset();
  };

  return (
    <Modal
      onClosed={onCloseModal}
      isOpen={isOpen}
      toggle={toggle}
      centered
      style={{ maxWidth: 1200, borderRadius: 0 }}
      wrapClassName="designStudioConfirmModal"
    >
      <ModalBody>
        <div className="text-center bold mb-4" style={{ fontSize: 20 }}>
          Search
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div></div>
          <Row className="d-flex align-items-end w-100">
            <Col md="11">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>First Name</Label>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="lookupModalInput"
                          type="text"
                          {...field}
                          placeholder="Enter first name"
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Last Name</Label>

                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="lookupModalInput"
                          type="text"
                          {...field}
                          placeholder="Enter last name"
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>Email</Label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="lookupModalInput"
                          type="text"
                          {...field}
                          placeholder="Enter email"
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  {showPhoneNumber ? (
                    <FormGroup>
                      <Label>Phone Number</Label>

                      <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field }) => (
                          <Input
                            className="lookupModalInput"
                            type="text"
                            {...field}
                            placeholder="Enter phone number"
                          />
                        )}
                      />
                    </FormGroup>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
            </Col>
            <Col md="1" className="d-flex justify-content-center">
              <Button
                type="submit"
                className="mb-3"
                color="primary"
                disabled={!Object.keys(dirtyFields).length}
              >
                {loading ? <Spinner size="sm" /> : 'Search'}
              </Button>
            </Col>
          </Row>
        </form>
        {leads.length ? (
          <>
            <div className="lookupTableWrapper mb-4">
              {' '}
              <Table bordered size="sm" className="lookupTable">
                <tbody>
                  {leads.map((lead) => (
                    <tr
                      key={lead.email}
                      onClick={() => setSelectedLead(lead)}
                      style={{ backgroundColor: selectedLead === lead ? '#ebe5d9' : 'white' }}
                    >
                      <td>{lead.name}</td>
                      <td>{lead.email}</td>
                      <td>{lead.department}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            {selectedLead ? (
              <div className="d-flex w-100 justify-content-center">
                <button type="button" className="grayButton" onClick={toggle}>
                  Cancel
                </button>
                <Button
                  type="button"
                  onClick={() => onLeadSelect(selectedLead!)}
                  style={{ marginLeft: 20, padding: '5px 19px' }}
                  color="primary"
                >
                  Select
                </Button>
              </div>
            ) : (
              ''
            )}
          </>
        ) : null}
      </ModalBody>
    </Modal>
  );
};

export default BelmondLookupModal;
