import { useOktaAuth } from '@okta/okta-react';
import moment from 'moment';
import { useEffect, useState, type FC } from 'react';
import { Controller, ControllerFieldState, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Select from 'react-select';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import WorkdayLogo from '../../../../assets/images/icons/WorkDayLogoRounded.svg';
import DropdownIndicator from '../../../../components/SelectDropdownArrow';
import { defaultProject } from '../../../../constants';
import { setAuthToken } from '../../../../services/api';
import designStudioService from '../../../../services/api/design-studio';
import {
  WorkdayProjectDetails,
  type Message,
  type Project,
  type Property,
  type Status,
} from '../../../../services/api/design-studio/types';
import SkeletonedComponent from '../../../guest-dashboard2/SkeletonedComponent';
import FooterActionbar from '../../components/FooterActionbar';
import '../../styles.css';
import { BelmondLookupModal } from '../components';
import { ENV } from '../../../../globals';

interface ApiResponse<T> {
  status: string;
  data: T[];
  meta: {
    page: number;
    limit: number;
    totalItems: number;
    totalPages: number;
  };
}

interface Permissions {
  superAdmin: boolean;
  email: string;
  integrations: string;
  allowedProperties: string[];
  locationCodes: string[];
}

const WORKDAY_LINK =
  ENV === 'dev'
    ? 'https://wd3-impl-sso.workday.com/belmond5/d/inst/view/'
    : 'https://wd3-sso.myworkday.com/belmond/d/inst/view/';

const inputStyle = (fieldState: ControllerFieldState) => ({
  border: fieldState.isDirty ? '1px solid #997b40' : '1px solid #dfd6c4',
  backgroundColor: 'white',
});

const Details: FC = () => {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const { project } = useOutletContext<{
    project: Project;
  }>();
  const permissions = useSelector(
    (state: { userPermissions: { permissions: Permissions } }) => state.userPermissions.permissions,
  );

  const [designStatuses, setDesignStatuses] = useState<Status[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const [workdayProjects, setWorkdayProjects] = useState<WorkdayProjectDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message>({ type: '', text: '' });
  const [belmondLookupModalOpen, setBelmondLookupModalOpen] = useState<boolean>(false);
  const newProject = !project?.id;

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
    setValue,
    watch,
  } = useForm<Project>({
    defaultValues: newProject ? defaultProject : project,
  });

  const selectedWorkdayProject = watch('workdayProjectReference');

  useEffect(() => {
    if (!authState?.idToken?.idToken) return;
    setAuthToken(authState.idToken.idToken);

    const fetchData = async () => {
      setLoading(true);
      try {
        const [designStatusResponse, propertiesResponse, projectLeadList] = await Promise.all([
          designStudioService.getDesignStatuses(),
          designStudioService.getProperties(),
          designStudioService.getLeadsList({
            department: null,
            isActive: true,
            email: authState?.idToken?.claims?.email ?? null,
            search: '',
          }),
        ]);

        setDesignStatuses((designStatusResponse?.data as unknown as ApiResponse<Status>).data);
        setProperties((propertiesResponse?.data as unknown as ApiResponse<Property>).data);
        if (newProject) {
          setValue('projectLeadId', projectLeadList?.data?.data[0]?.id);
          setValue('projectLead', projectLeadList?.data?.data[0]);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authState?.idToken?.idToken]);

  const onSubmit = async (values: Project) => {
    try {
      const formattedData = {
        name: values.projectName,
        description: values.description,
        propertyCode: values.propertyCode,
        propertyName: values.propertyName,
        projectLeadId: values.projectLeadId,
        designStatusId: values.designStatusId,
        projectNameId: values.projectName,
        projectValueForecasted: Number(values.projectValueForecasted),
        projectValueActual: Number(values.projectValueActual),
        completionDate: moment(values.completionDate).format('YYYY-MM-DD'),
        startDate: moment(values.startDate).format('YYYY-MM-DD'),
        workdayProjectValue: Number(values.workdayProjectValue),
        sharePointURL: values.sharePointUrl,
        ...(newProject && {
          region: '550e8400-e29b-41d4-a716-446655440010',
          projectValue: 0,
          localCurrencyId: '550e8400-e29b-41d4-a716-446655440006',
        }),
      };

      if (newProject) {
        await designStudioService.createProject(formattedData);
        navigate('/design-studio/projects');
        setMessage({ type: 'success', text: 'Project created successfully!' });
      } else {
        await designStudioService.updateProject(project!.id, formattedData);
        reset(values);
        setMessage({ type: 'success', text: 'Saved!' });
      }
    } catch (err) {
      setMessage({ type: 'fail', text: 'Error occured!' });
    }
  };

  const handleCancelButtonClick = () => {
    if (newProject) {
      reset(defaultProject);
    } else {
      reset(project);
    }
  };

  const fetchWorkdayProjects = async () => {
    try {
      const { data } = await designStudioService.getWorkdayProjectDetails(
        watch('projectLead.email')?.toLowerCase() || '',
      );
      setWorkdayProjects(data?.data);
      setValue('workdayProjectReference', data?.data[0]?.projectId);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchWorkdayProjects();
  }, [watch('projectLeadId')]);

  useEffect(() => {
    if (message.type) {
      setTimeout(() => setMessage({ text: '', type: '' }), 3000);
    }
  }, [message]);

  return (
    <div>
      <BelmondLookupModal
        showPhoneNumber={false}
        isOpen={belmondLookupModalOpen}
        toggle={() => setBelmondLookupModalOpen(false)}
        onLeadSelect={(lead) => {
          setValue('projectLeadId', lead?.id);
          setValue('projectLead', lead, { shouldDirty: true });
          setBelmondLookupModalOpen(false);
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className="containerMaxWidth" fluid>
          <Row className="g-5 w-100 mb-5">
            <Col md="6" className="d-flex flex-column h-100">
              <div>
                <h5 className="mb-4 bold">Project Details</h5>

                <FormGroup>
                  <Label>Project Status</Label>
                  <SkeletonedComponent condition={!loading} height={35}>
                    <Controller
                      name="designStatusId"
                      control={control}
                      rules={{ required: 'Project status is required' }}
                      render={({ field, fieldState }) => (
                        <Input type="select" {...field} style={inputStyle(fieldState)}>
                          <option value="">Select status...</option>
                          {designStatuses.map((status) => (
                            <option key={status.id} value={status.id}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </SkeletonedComponent>
                </FormGroup>

                <FormGroup
                  onDoubleClick={() => permissions?.superAdmin && setBelmondLookupModalOpen(true)}
                >
                  <Label>Project Lead *</Label>
                  <SkeletonedComponent condition={!loading} height={35}>
                    <Controller
                      name="projectLead.name"
                      control={control}
                      rules={{ required: 'Project name is required' }}
                      render={({ field, fieldState }) => (
                        <Input style={inputStyle(fieldState)} type="text" {...field} />
                      )}
                    />
                  </SkeletonedComponent>
                </FormGroup>

                <FormGroup>
                  <Label>Property *</Label>
                  <SkeletonedComponent condition={!loading} height={35}>
                    <Controller
                      name="propertyCode"
                      control={control}
                      defaultValue={project?.propertyCode}
                      render={({ field, fieldState }) => (
                        <Select
                          components={{ DropdownIndicator }}
                          options={properties.map((p) => ({
                            value: p.referenceId,
                            label: p.name,
                          }))}
                          value={{ value: watch('propertyCode'), label: watch('propertyName') }}
                          onChange={(code) => {
                            setValue('propertyName', code!.label);
                            field.onChange(code!.value);
                          }}
                          isSearchable
                          placeholder="Select property"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              border: fieldState.isDirty
                                ? '1px solid #997b40'
                                : '1px solid #dfd6c4',
                              boxShadow: 'none',
                            }),
                            option: (baseStyles, { isSelected }) => ({
                              ...baseStyles,
                              backgroundColor: isSelected ? '#F6F4F0' : 'white',
                              color: 'black',
                              cursor: 'pointer',
                            }),
                          }}
                          className="PropertySelect"
                        />
                      )}
                    />
                  </SkeletonedComponent>
                </FormGroup>

                <FormGroup>
                  <Label>Start Date *</Label>
                  <SkeletonedComponent condition={!loading} height={35}>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: 'Project name is required' }}
                      render={({ field, fieldState }) => (
                        <Input style={inputStyle(fieldState)} type="text" {...field} />
                      )}
                    />
                  </SkeletonedComponent>
                </FormGroup>

                <FormGroup>
                  <Label>Projected Completion date *</Label>
                  <SkeletonedComponent condition={!loading} height={35}>
                    <Controller
                      name="completionDate"
                      control={control}
                      rules={{ required: 'Project name is required' }}
                      render={({ field, fieldState }) => (
                        <Input style={inputStyle(fieldState)} type="text" {...field} />
                      )}
                    />
                  </SkeletonedComponent>
                </FormGroup>

                <FormGroup>
                  <Label>Project Name *</Label>
                  <SkeletonedComponent condition={!loading} height={35}>
                    <Controller
                      name="projectName"
                      control={control}
                      rules={{ required: 'Project name is required' }}
                      render={({ field, fieldState }) => (
                        <Input style={inputStyle(fieldState)} type="text" {...field} />
                      )}
                    />
                  </SkeletonedComponent>
                </FormGroup>

                <FormGroup>
                  <Label>Project Description *</Label>
                  <SkeletonedComponent condition={!loading} height={110}>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Input style={inputStyle(fieldState)} type="textarea" {...field} rows={4} />
                      )}
                    />
                  </SkeletonedComponent>
                </FormGroup>

                <FormGroup>
                  <Label>SharePoint URL *</Label>
                  <SkeletonedComponent condition={!loading} height={35}>
                    <Controller
                      name="sharePointUrl"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Input style={inputStyle(fieldState)} type="text" {...field} />
                      )}
                    />
                  </SkeletonedComponent>
                </FormGroup>

                <FormGroup>
                  <Label>Unique Project ID</Label>
                  <SkeletonedComponent condition={!loading} height={35}>
                    <Controller
                      name="id"
                      disabled
                      control={control}
                      render={({ field, fieldState }) => (
                        <Input style={inputStyle(fieldState)} type="text" {...field} />
                      )}
                    />
                  </SkeletonedComponent>
                </FormGroup>
              </div>
            </Col>
            <Col md={6}>
              <div className="fontSettings">
                <h5 className="mb-4 bold">Financial Details</h5>
                <Row className="p-3 mb-3" style={{ backgroundColor: '#f6f4f0' }}>
                  <FormGroup>
                    <Label>Link to Workday project</Label>
                    <SkeletonedComponent condition={!loading} height={35}>
                      <Controller
                        name="workdayProjectReference"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input style={inputStyle(fieldState)} type="select" {...field}>
                            {workdayProjects.map((p) => (
                              <option key={p.projectId} value={p.projectId}>
                                {p.project}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </SkeletonedComponent>
                    <div className="text-end mt-2">
                      <a
                        href={`${WORKDAY_LINK}${selectedWorkdayProject}.htmld`}
                        className="text-primary text-decoration-none d-flex align-items-center justify-content-end"
                      >
                        <span style={{ borderBottom: '1px solid #997B40' }}>
                          View project in Workday{' '}
                        </span>
                        <img src={WorkdayLogo} alt="workday-logo" style={{ marginLeft: 10 }} />
                      </a>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Workday Project Value *</Label>
                    <SkeletonedComponent condition={!loading} height={35}>
                      <Controller
                        name="workdayProjectValue"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input style={inputStyle(fieldState)} type="number" {...field} />
                        )}
                      />
                    </SkeletonedComponent>
                  </FormGroup>
                  <FormGroup>
                    <Label>Total Proposed Budget *</Label>
                    <SkeletonedComponent condition={!loading} height={35}>
                      <Controller
                        name="projectValueForecasted"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input style={inputStyle(fieldState)} type="number" {...field} />
                        )}
                      />
                    </SkeletonedComponent>
                  </FormGroup>
                  <FormGroup>
                    <Label>Total Actual *</Label>
                    <SkeletonedComponent condition={!loading} height={35}>
                      <Controller
                        name="projectValueActual"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input style={inputStyle(fieldState)} type="number" {...field} />
                        )}
                      />
                    </SkeletonedComponent>
                  </FormGroup>
                  <FormGroup>
                    <Label>Total Forecasted *</Label>
                    <SkeletonedComponent condition={!loading} height={35}>
                      <Controller
                        name="projectValueForecasted"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input style={inputStyle(fieldState)} type="number" {...field} />
                        )}
                      />
                    </SkeletonedComponent>
                  </FormGroup>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        {Object.keys(dirtyFields).length || message.type ? (
          <FooterActionbar onCancel={handleCancelButtonClick} message={message} />
        ) : (
          ''
        )}
      </form>
    </div>
  );
};

export default Details;
