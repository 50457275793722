import { useEffect, useRef, useState } from 'react';
import { Controller, ControllerFieldState, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import crossIcon from '../../../assets/images/icons/Cross.svg';
import { defaultAddress } from '../../../constants';
import useGoogleMapsLookup from '../../../hooks/useGoogleMapsLookup';
import designStudioService from '../../../services/api/design-studio';
import SkeletonedComponent from '../../guest-dashboard2/SkeletonedComponent';
import FooterActionbar from '../components/FooterActionbar';
import '../styles.css';
import { Address, CountrySelectOption } from '../../../services/api/design-studio/types';
import ConfirmModal from '../components/ConfirmModal';

const inputStyle = (fieldState: ControllerFieldState) => ({
  border: fieldState.isDirty ? '1px solid #997b40' : '1px solid #dfd6c4',
});

interface OutletContextType {
  countries: CountrySelectOption[];
  addressTypes: { id: string; name: string }[];
}

const AddressView = () => {
  const navigate = useNavigate();
  const { addressId, id: projectId } = useParams();
  const { countries, addressTypes } = useOutletContext<OutletContextType>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [placeValues, googleMapsLoaded] = useGoogleMapsLookup(inputRef);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [loading, setLoading] = useState(false);

  const [originalAddress, setOriginalAddress] = useState<Partial<Address> | null>(null);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const newAddress = !addressId || addressId === 'new';
  const {
    control,
    setValue,
    formState: { dirtyFields },
    handleSubmit,
    reset,
  } = useForm<Address>({
    defaultValues: defaultAddress,
  });

  const fetchAddress = async () => {
    if (newAddress) return;
    try {
      setLoading(true);
      const {
        data: { data },
      } = await designStudioService.getProjectAddress(projectId!, addressId!);

      const formattedAddress = {
        id: addressId,
        streetName: data.streetName,
        city: data.city,
        stateProvince: data.stateProvince,
        postalCode: data.postalCode,
        isPrimary: data.isPrimary,
        addressType: data.typeId,
        countryCode: data.countryId,
      };
      setOriginalAddress(formattedAddress);
      reset(formattedAddress);
    } catch (e) {
      setMessage({ type: 'error', text: 'Failed to load address' });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: Address) => {
    const formattedAddress = {
      streetName: data.streetName,
      city: data.city,
      stateProvince: data.stateProvince,
      postalCode: data.postalCode,
      isPrimary: data.isPrimary,
      typeId: data.addressType,
      countryId: data.countryCode,
    };
    try {
      if (newAddress) {
        const newAddressToAdd = {
          ...formattedAddress,
          projectId,
        };
        const { data: test } = await designStudioService.createProjectAddress(
          projectId!,
          newAddressToAdd,
        );
        setMessage({ type: 'success', text: 'Address created successfully!' });
        reset(data);
        navigate(`/design-studio/projects/${projectId}/addresses/${test.data.id}/details`);
      } else {
        await designStudioService.updateProjectAddress(projectId!, addressId!, formattedAddress);
        reset(data);
        setOriginalAddress(data);
        setMessage({ type: 'success', text: 'Address updated successfully!' });
      }
    } catch (e) {
      setMessage({ type: 'error', text: 'Error occurred, please try again.' });
    }
  };

  const handleCancelButtonClick = () => {
    if (newAddress) {
      reset(defaultAddress);
    } else {
      reset(originalAddress || defaultAddress);
    }
  };

  const handleDeleteButtonClick = async () => {
    setLoading(true);
    try {
      await designStudioService.deleteProjectAddress(projectId!, addressId!);
      navigate(-1);
    } catch (e) {
      setMessage({ type: 'error', text: 'Failed to delete address' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  useEffect(() => {
    if (message.type) {
      setTimeout(() => setMessage({ text: '', type: '' }), 3000);
    }
  }, [message]);

  useEffect(() => {
    if (placeValues) {
      Object.entries(placeValues).forEach(([key, value]) => {
        if (key === 'countryCode') {
          const mappedCountry = countries.find((country) => country.isoCode === value);
          setValue('countryCode', mappedCountry?.id || '', { shouldDirty: true });
        } else {
          setValue(key as keyof Address, value as string | boolean, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      });
    }
  }, [placeValues, setValue]);

  const renderDeleteButton = (onClick: () => void) => (
    <button type="button" onClick={onClick} className="redButton d-flex align-items-center">
      <img src={crossIcon} alt="Delete" width={11} height={11} style={{ marginRight: 5 }} />
      Delete
    </button>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmModal
        open={confirmDeleteModalOpen}
        onClose={() => setConfirmDeleteModalOpen(false)}
        loading={loading}
        submitButton={renderDeleteButton(handleDeleteButtonClick)}
        title="Delete address"
        message="This action can't be reversed, are you sure you want to delete this address?"
      />
      <Container fluid className="containerMaxWidth">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="bold">Address details</h5>
          {!newAddress && (
            <div className="d-flex">
              {/* <button type="button" className="grayButton">
                  Duplicate
                </button>
           */}
              <div className="mx-2"></div>
              {renderDeleteButton(() => setConfirmDeleteModalOpen(true))}
            </div>
          )}
        </div>
        <Row className="mb-4">
          <Col md="4">
            <FormGroup>
              <Label>Address type</Label>
              <SkeletonedComponent condition={!loading} height={35}>
                <Controller
                  name="addressType"
                  control={control}
                  rules={{ required: 'Design status is required' }}
                  render={({ field, fieldState }) => (
                    <Input type="select" {...field} style={inputStyle(fieldState)}>
                      <option value="">Select address type...</option>
                      {addressTypes.map((address) => (
                        <option key={address.id} value={address.id}>
                          {address.name}
                        </option>
                      ))}
                    </Input>
                  )}
                />
              </SkeletonedComponent>
            </FormGroup>
          </Col>
        </Row>
        <h5 className="bold mb-4">Address</h5>

        <Row>
          <Col>
            <FormGroup>
              <Label>Look up address</Label>
              <SkeletonedComponent condition={!!googleMapsLoaded} height={35}>
                <Input
                  innerRef={inputRef}
                  style={{
                    border: '1px solid #dfd6c4',
                  }}
                  type="text"
                  placeholder="Start typing address"
                  autocomplete="new-password"
                />
              </SkeletonedComponent>
            </FormGroup>
          </Col>
        </Row>

        <Row className="g-5 my-2">
          <Col md="6" className="m-0">
            {' '}
            <FormGroup>
              <Label>Address line 1</Label>
              <SkeletonedComponent condition={!loading} height={35}>
                <Controller
                  name="streetName"
                  control={control}
                  rules={{ required: 'Street Address is required' }}
                  render={({ field, fieldState }) => (
                    <Input style={inputStyle(fieldState)} type="text" {...field} />
                  )}
                />
              </SkeletonedComponent>
            </FormGroup>
          </Col>
          <Col md="6" className="m-0">
            {' '}
            <FormGroup>
              <Label>City / Town</Label>
              <SkeletonedComponent condition={!loading} height={35}>
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: 'City is required' }}
                  render={({ field, fieldState }) => (
                    <Input style={inputStyle(fieldState)} type="text" {...field} />
                  )}
                />
              </SkeletonedComponent>
            </FormGroup>
          </Col>
        </Row>

        <Row className="g-5">
          <Col md="4">
            {' '}
            <FormGroup>
              <Label>County / State</Label>
              <SkeletonedComponent condition={!loading} height={35}>
                <Controller
                  name="stateProvince"
                  control={control}
                  rules={{ required: 'County / State is required' }}
                  render={({ field, fieldState }) => (
                    <Input style={inputStyle(fieldState)} type="text" {...field} />
                  )}
                />
              </SkeletonedComponent>
            </FormGroup>
          </Col>
          <Col md="4">
            {' '}
            <FormGroup>
              <Label>Zip / Post code</Label>
              <SkeletonedComponent condition={!loading} height={35}>
                <Controller
                  name="postalCode"
                  control={control}
                  rules={{ required: 'Zip / Post Code is required' }}
                  render={({ field, fieldState }) => (
                    <Input style={inputStyle(fieldState)} type="text" {...field} />
                  )}
                />
              </SkeletonedComponent>
            </FormGroup>
          </Col>
          <Col md="4">
            {' '}
            <FormGroup>
              <Label>Country</Label>
              <SkeletonedComponent condition={!loading} height={35}>
                <Controller
                  name="countryCode"
                  control={control}
                  rules={{ required: 'City is required' }}
                  render={({ field, fieldState }) => (
                    <Input style={inputStyle(fieldState)} type="select" {...field}>
                      <option value="">Please select</option>
                      {countries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </Input>
                  )}
                />
              </SkeletonedComponent>
            </FormGroup>
          </Col>
        </Row>
      </Container>
      {Object.keys(dirtyFields).length || message.type ? (
        <FooterActionbar onCancel={handleCancelButtonClick} message={message} />
      ) : (
        ''
      )}
    </form>
  );
};

export default AddressView;
