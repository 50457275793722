import { useEffect, useState } from 'react';
import { NavLink, Outlet, useOutletContext, useParams } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import {
  Project,
  Country,
  AddressType,
  CountrySelectOption,
} from '../../../services/api/design-studio/types';
import designStudioService from '../../../services/api/design-studio';

const AddressesLayout = () => {
  const { project, setRouteBackButton } = useOutletContext<{
    project: Project;
    setRouteBackButton: (routeName?: string) => void;
  }>();
  const { addressId } = useParams();

  const [countries, setCountries] = useState<CountrySelectOption[]>([]);
  const [addressTypes, setAddressTypes] = useState<{ id: string; name: string }[]>([]);

  const fetchMetadata = async () => {
    const [{ data: countriesList }, { data: addressTypesList }] = await Promise.all([
      designStudioService.getCountries(),
      designStudioService.getAddressTypes(),
    ]);

    setCountries(
      countriesList.data.map((country: Country) => ({
        id: country.id,
        name: country.name,
        isoCode: country.isoCode2,
      })),
    );
    setAddressTypes(
      addressTypesList.data.map((addressType: AddressType) => ({
        id: addressType.id,
        name: addressType.name,
      })),
    );
  };

  useEffect(() => {
    if (addressId) {
      setRouteBackButton('addresses');
    }

    return () => {
      setRouteBackButton();
    };
  }, [addressId]);

  useEffect(() => {
    fetchMetadata();
  }, []);

  const nav = [{ href: 'details', title: 'Details' }];
  return (
    <div>
      {addressId && (
        <SimpleBar>
          <Nav className="justify-content-center p-2">
            {nav.map((item) => (
              <NavItem key={item.href} className="mx-2">
                <NavLink
                  to={`/design-studio/projects/${project.id}/addresses/${addressId}/${item.href}`}
                  className="px-3 py-1 rounded-pill"
                  style={({ isActive }) => ({
                    backgroundColor: isActive ? 'var(--bs-primary)' : 'white',
                    color: isActive ? 'white' : 'var(--bs-primary)',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '14px',
                    textDecoration: 'none',
                  })}
                >
                  {item.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </SimpleBar>
      )}
      <Outlet context={{ project, countries, addressTypes }} />
    </div>
  );
};

export default AddressesLayout;
